import React from 'react';

interface ButtonProps {
  text: string;
  onClick?: () => void;
  color?: string;
  textColor?: string;
  borderColor?: string;
  loading?: boolean;
  className?: string;
   
}

const Button: React.FC<ButtonProps> = ({
  text,
  onClick,
  color = '#2196F3',
  textColor = '#FFFFFF',
  borderColor,
  loading = false, 
  className
}) => {
  const handleClick = () => {
    if (onClick && !loading) { 
      onClick();
    }
  };

  return (
    <div
      onClick={handleClick}
      className="flex w-full flex-col px-[22px] py-[8px] rounded text-center cursor-pointer"
      style={{
        backgroundColor: color,
        color: textColor,
        border: borderColor ? `2px solid ${borderColor}` : 'none',
      }}
    >
      <span className="text-[15px] font-medium leading-[26px] flex justify-center items-center">
        {loading ? (
          <div className="w-5 h-5 border-2 mt-1border-t-4 border-t-white border-transparent rounded-full animate-spin"></div>
        ) : (
          text
        )}
      </span>
    </div>
  );
};

export default Button;
