import React, { useState, useEffect } from 'react';
import { bottomSheet } from 'react-simple-bottom-sheet';
import Paid from './Paid';
import success from '../assets/success.png';
import failed from '../assets/failed.png';
import timeout from '../assets/timeout.png';
import Header from '../components/common/Header';
import Footer from '../components/common/Footer';
import PaymentFailedTimeExp from './PaymentFailedTimeExp';
import PaymentCancellationReason from './PaymentCancellationReason';
import { fetchPaymentSummary, getPaymentUrl, getTransactionStatus, updateStatus } from '../api';
import { useGlobalContext } from '../context/BookingContext';
import { usePaymentStatus } from '../context/PaymentStatusContext';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../store';
import { setTimeLeft, decrementTime } from '../store/timerSlice';
import PaymentFailedCVV from './PaymentFailedCVV';
import LoadingSpinner from '../components/common/PaymentLoader';
import PaymentFailedCancelled from './PaymentFailedCancelled';


const PaymentSummary: React.FC = () => {
  const { bookingId, expiresIn, iat } = useGlobalContext();
  
  const [isBookingDetailsOpen, setIsBookingDetailsOpen] = useState(true);
  const [paymentData, setPaymentData] = useState<any>(null);
  const [expired, setExpired] = useState(false);
  const dispatch = useDispatch();
  const timeLeft = useSelector((state: RootState) => state.timeLeft);

  const [paymentUrl, setPaymentUrl] = useState("");
  const [apiError, setApiError] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const { paymentStatus, setPaymentStatus } = usePaymentStatus();

  const [paymentFlag, setPaymentFlag] = useState("initial");


  const safeBookingId = Number(bookingId);


  const marginBottom = !expired
    ? (paymentStatus !== 'CAPTURED' && paymentStatus !== 'FAILED' && paymentStatus !== 'CANCELLED' ? 'mb-[102px]' : 'mb-[32px]')
    : 'mb-[32px]';


  useEffect(() => {
    const storedPaymentStatus = sessionStorage.getItem(`paymentStatus-${safeBookingId}`);
    if (storedPaymentStatus) {
      setPaymentStatus(storedPaymentStatus as 'CAPTURED' | 'FAILED' | 'CANCELLED');
    }

    if (safeBookingId) {
      fetchPaymentSummary(safeBookingId)
        .then((data) => {
          setPaymentData(data.data);
        })
        .catch((error) => {
          console.error('Error fetching payment summary:', error);
          // Handle different error cases
          // if (error.response) {
          //   if (error.response.status === 404) {
          //     navigate('/404');
          //   } else if (error.response.status === 500) {
          //     navigate('/500');
          //   }
          // } else {
          //   navigate('/500');
          // }
          setPaymentData(null);
        });
    }
  }, [safeBookingId]);



  // Format time remaining
  const formatTimeLeft = () => {
    const minutes = Math.floor(timeLeft / 60);
    const seconds = timeLeft % 60;
    return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      dispatch(decrementTime());
    }, 1000);

    // Handle payment failure scenarios immediately
    if (paymentStatus && ['CAPTURED', 'CANCELLED', 'FAILED'].includes(paymentStatus)) {
      if (timer) clearInterval(timer); // Ensure timer is stopped
  
      setExpired(true);
      sessionStorage.setItem(`paymentStatus-${safeBookingId}`, paymentStatus);
      dispatch(setTimeLeft(0)); // Properly reset timer in Redux

      // Open Bottom Sheet based on paymentStatus
      if (paymentStatus === 'CAPTURED') {
        openBottomSheet(
          <Paid
            booking_id={safeBookingId}
            amount={paymentData?.fare_breakup.total}
            transaction_id={'Id-123456'}
            name={paymentData?.name}
            payment_reference={paymentData?.external_transaction_ref_number}
            payment_type={'DEBIT CARD'}
            payment_timestamp={new Date().toLocaleString()}
          />
        );
      } else if (paymentStatus === 'CANCELLED') {
        openBottomSheet(
          <PaymentFailedCancelled timestamp={new Date().toLocaleString()} />
        );
      } else if (paymentStatus === 'FAILED') {
        openBottomSheet(
          <PaymentCancellationReason bookingId={safeBookingId} />
        );
      }
    }
  
    // Cleanup on unmount
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [paymentStatus, dispatch, expiresIn, timeLeft]);
  


  useEffect(() => {
    if (timeLeft <= 0 && !expired) {
      // Ensuring paymentStatus is defined before checking its value
      if (!paymentStatus || !['CAPTURED', 'CANCELLED', 'FAILED'].includes(paymentStatus)) {
        setExpired(true);

        // Ensure bottom sheet opens only once
        setTimeout(() => {
          openBottomSheet(<PaymentFailedTimeExp timestamp={new Date().toLocaleString()} />);
        }, 0);
      }
    }
  }, [timeLeft, expired, paymentStatus]);


  const openBottomSheet = (content: React.ReactNode) =>
    bottomSheet.create({ content });

  const toggleBookingDetails = () => {
    setIsBookingDetailsOpen((prev) => !prev);
  };

  useEffect(() => {
    const reloadComplete = sessionStorage.getItem("reloadComplete");
    if (reloadComplete) {
        sessionStorage.removeItem("reloadComplete"); // Clear the flag
        closePopup(); // Call the function automatically
    }
}, []);

  

  const handlePayNow = async () => {
    setPaymentFlag("processing");

    // #test
    // setTimeout(() => {
    //   setPaymentFlag("confirming");
    //   setIsLoading(true);
    //   setIsPopupOpen(true);
    //   setPaymentUrl("https://checkout.wompi.co/l/test_Lvov60");
    // }, 500);

    const token = sessionStorage.getItem('token')
    try {
      const payload = {
        booking_id: safeBookingId,
        user_name: paymentData?.name,
        amount: paymentData?.fare_breakup?.total.toString(),
        in_currency: 'COP',
        out_currency: 'COP',
        // redirect_url: `http://localhost:3000/payment-summary?token=${token}`
        redirect_url: `${process.env.REACT_APP_PAYMENT_SUMMARY_URL}/payment-summary?token=${token}`
      }
      const paymentResponse = await getPaymentUrl({ ...payload });
      if (paymentResponse?.data) {
        sessionStorage.setItem("reloadComplete", "true");
        setTimeout(() => {
          setPaymentFlag("confirming");
          setIsLoading(true);
          window.location.href = paymentResponse?.data;
          setPaymentUrl(paymentResponse.data);
        }, 500);

      } else {
        throw new Error("Failed to fetch payment URL");
      }
      return paymentResponse.data;
    } catch (error) {
      setApiError("Error fetching payment URL. Please try again.");
    } finally {
      setPaymentFlag("intial");
    }
  };

  const closePopup = () => {
    if (expired) return;
    setIsLoading(true); 
    setPaymentFlag("confirming");

    // Wait 30 seconds before checking transaction status
    setTimeout(async () => {
      let response;

      const storedBookingId = sessionStorage.getItem("bookingId");

      if (!storedBookingId) {
        console.error("Booking ID not found in session storage");
        return;
      }
      try {
        response = await getTransactionStatus(storedBookingId);
        console.log("Transaction Status:", response.paymentStatus);

        if (response?.paymentStatus === "CAPTURED") {
          setIsLoading(false); // Hide loading state after response
          setPaymentFlag("success");
          setPaymentStatus("CAPTURED");
          sessionStorage.setItem(`paymentStatus-${safeBookingId}`, 'PAID');

          openBottomSheet(
            <Paid
              booking_id={safeBookingId}
              amount={paymentData.fare_breakup.total}
              transaction_id={response.paymentTransactionId}
              name={paymentData.name}
              payment_reference={paymentData.payment_reference}
              payment_type={'DEBIT CARD'}
              payment_timestamp={response.paymentDate}
            />
          );
        } else {
          throw new Error("Payment not CAPTURED");
        }
      } catch (error) {
        setIsLoading(false); // Hide loading state after failure
        setPaymentFlag("failed");

        const errorMessage = "Transaction verification timed out. Please try again.";
        setApiError(errorMessage);

        openBottomSheet(
          <PaymentFailedCVV
            booking_id={safeBookingId}
            reason={errorMessage}
            timestamp={response?.paymentDate}
            transcationId={response?.paymentTransactionId}
          />
        );
      }
    }, 30000);
  };

  return (
    <div className="flex flex-col w-full">
      <Header timeLeft={formatTimeLeft()} />

      <div className="flex flex-col pt-6 px-6 bg-[white] w-full gap-[20px] screen-size">
        <div className="text-center flex flex-col gap-[4px]">

          {!expired &&
            !(
              paymentStatus === "CAPTURED" ||
              paymentStatus === "CANCELLED" ||
              paymentStatus === "FAILED" ||
              paymentFlag === "processing" ||
              paymentFlag === "confirming"
            ) && (
              <>
                <p className="text-base">You are paying</p>
                <p className="text-6xl font-bold text-[#03A9F4]">
                  ${paymentData ? paymentData.fare_breakup.total : '0'}
                </p>
                <p className="text-lg font-bold">Payment Summary</p>
              </>
            )
          }

          {/* {apiError && <p className="text-red-500 text-[12px] text-center pt-[20px]">{apiError}</p>} */}


          {/* Time Expired Status */}
          {expired && !(paymentStatus === 'CAPTURED' || paymentStatus === 'FAILED' || paymentStatus === 'CANCELLED') && (
            <div className="flex flex-col gap-3 items-center pt-[8px]">
              <img src={timeout} alt="timeout-img" className="w-[54px] h-[54px] object-contain" />
              <div className="text-[18px] font-medium leading-[21px] text-orange-700">Time Expired</div>
              <div className="flex flex-col text-center font-normal text-[14px] leading-[21px] font-gantari text-[#D32F2F]">
                Your payment time has expired,<br />
                Please contact the service provider to retry.
              </div>
            </div>
          )}


          {/* PAID Status */}
          {paymentStatus === 'CAPTURED' && (
            <div className="flex flex-col gap-3 items-center pt-[8px]">
              <img src={success} alt="success-img" className="w-[54px] h-[54px] object-contain" />
              <div className="text-[18px] font-medium leading-[21px] text-green-700">Payment Completed Successfully</div>
            </div>
          )}

          {/* FAILED Status */}
          {paymentStatus === 'FAILED' && (
            <div className="flex flex-col gap-3 items-center pt-[8px]">
              <img src={failed} alt="failed-img" className="w-[54px] h-[54px] object-contain" />
              <div className="text-[18px] font-medium leading-[21px] text-red-700">Payment Failed</div>
            </div>
          )}

          {/* CANCELLED Status */}
          {paymentStatus === 'CANCELLED' && (
            <div className="flex flex-col gap-3 items-center pt-[8px] text-red-700">
              <img src={failed} alt="cancelled-img" className="w-[54px] h-[54px] object-contain" />
              <div className="text-[18px] font-normal leading-[21px]">Payment Cancelled</div>
            </div>
          )}
        </div>

        {/* Payment Info */}
        {paymentData && paymentFlag !== "processing" && paymentFlag !== 'confirming' && (
          <>
            <>
              <SummarySection
                title="Payment Details"
                details={[
                  { label: 'Name', value: paymentData.name || 'NA' },
                  { label: 'Payment Reference', value: paymentData.external_transaction_ref_number || 'NA' },
                ]}
              />

              {/* Fare Breakup */}
              <SummarySection
                title="Fare Breakup"
                details={[
                  { label: 'Trip Fare', value: `$${paymentData.fare_breakup.trip_fare || '0'}` },
                  { label: 'Service Charge (Inc)', value: `$${paymentData.fare_breakup.service_charge || '0'}` },
                  { label: 'Tax', value: `$${paymentData.fare_breakup.tax || '0'}` },
                  { label: 'Total', value: `$${paymentData.fare_breakup.total}`, isBold: true || '0' },
                ]}
              />

              {/* Booking Details */}
              <div className="border border-gray-200 rounded-md pb-2">
                <button
                  onClick={toggleBookingDetails}
                  className="flex justify-between items-center w-full text-sm font-bold px-3 pt-4"
                >
                  Booking Details
                  <span
                    className={`transition-transform duration-300 transform ${isBookingDetailsOpen ? 'rotate-180' : ''
                      }`}
                  >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M5.29289 8.29289C4.90237 8.68342 4.90237 9.31658 5.29289 9.70711L11.2929 15.7071C11.6834 16.0976 12.3166 16.0976 12.7071 15.7071L18.7071 9.70711C19.0976 9.31658 19.0976 8.68342 18.7071 8.29289C18.3166 7.90237 17.6834 7.90237 17.2929 8.29289L12 13.5858L6.70711 8.29289C6.31658 7.90237 5.68342 7.90237 5.29289 8.29289Z"
                        fill="black"
                        fillOpacity="0.87"
                      />
                    </svg>
                  </span>
                </button>
                {isBookingDetailsOpen && (
                  <SummarySection
                    details={[
                      { label: 'Booking ID', value: paymentData.booking_details.booking_id.toString() || 'NA' },
                      { label: 'Booking Date', value: paymentData.booking_details.booking_date || 'NA' },
                      { label: 'Service Type', value: paymentData.booking_details.service_type || 'NA' },
                      { label: 'Distance Covered', value: paymentData.booking_details.distance_covered || 'NA' },
                      { label: 'Pick Up', value: paymentData.booking_details.pickup_location || 'NA' },
                      { label: 'Drop', value: paymentData.booking_details.drop_location || 'NA' },
                    ]}
                    noBorder
                  />
                )}
              </div>
            </>

            {/* Action Buttons */}
            <div className="flex flex-col justify-center text-center gap-2 text-[14px] leading-[24px] font-medium">
              {(!isLoading && !expired && paymentStatus !== 'CAPTURED' && paymentStatus !== 'CANCELLED' && paymentStatus !== 'FAILED') && (
                <button onClick={() => openBottomSheet(<PaymentCancellationReason bookingId={safeBookingId} />)}>
                  Cancel Payment
                </button>
              )}
            </div>

            <div className={`${marginBottom}`}>
              <Footer />
            </div>
          </>
        )}


        {/* Payment Popup Modal */}
        { paymentUrl && (
          <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white w-full h-full rounded-none shadow-lg relative flex flex-col justify-start">
              {/* <div className="p-4 pt-6 flex justify-center relative">
                <span className="text-red text-md animate-blink text-center px-8">
                  Once payment is completed, you can close this window safely.
                </span>
                <button
                  onClick={closePopup}
                  className="absolute top-5 right-4 rounded-full w-10 h-auto flex items-center justify-center text-black font-bold cursor-pointer"
                >
                  ✕
                </button>
              </div> */}
              <iframe src={paymentUrl} className="w-full flex-grow border-none"></iframe>
            </div>
          </div>


        )}

      </div>


      {paymentFlag === 'processing' && (
        <div className="flex flex-col justify-center items-center"
          style={{ height: 'calc(100vh - 88px)' }}
        >
          <div className="w-[160px] h-[90px] overflow-hidden flex flex-col items-center justify-center">
            <LoadingSpinner />
          </div>
          <div className="bg-white text-center flex flex-col gap-3">
            <h2 className="text-[24px] font-semibold">Payment in Progress.</h2>
            <p className="text-gray-500 text-[12px]">Please do not reload the page.</p>
          </div>
          <p className="text-gray-400 text-xs pt-4">
            Secured by <span className="text-blue-500 font-semibold">Conomy</span>
          </p>
        </div>
      )}

      {paymentFlag === 'confirming' && (
        <div className="bg-white text-center flex flex-col gap-3 justify-center items-center"
          style={{ height: 'calc(100vh - 88px)' }}
        >
          <h2 className="text-[24px] font-semibold">Confirming the Payment.</h2>
          <p className="text-gray-500 text-[12px]">
            This will only take a few seconds.
          </p>

          {/* Animated Image */}
          <div className="flex flex-col justify-center items-center">
            <div className="w-[160px] h-[90px] overflow-hidden flex flex-col items-center justify-center">
              <LoadingSpinner />
            </div>

            {/* Conomy Footer */}
            <p className="text-gray-400 text-xs pt-4">
              Secured by{" "}
              <span className="text-blue-500 font-semibold">Conomy</span>
            </p>
          </div>

        </div>
      )}

      {!expired &&
        paymentStatus !== "CAPTURED" &&
        paymentStatus !== "FAILED" &&
        paymentStatus !== "CANCELLED" &&
        paymentFlag !== "processing" &&
        paymentFlag !== "confirming" && (
          <div className="bg-white w-full flex flex-col fixed bottom-0 shadow-[0_-4px_8px_rgba(0,0,0,0.1)]">
            <button className="px-[24px] py-[20px]" onClick={handlePayNow} disabled={isLoading}>
              <div className="flex w-full flex-col px-[22px] py-[8px] rounded text-center cursor-pointer bg-[#2196F3]">
                {isLoading ? (
                  <span className="flex justify-center items-center text-[15px] font-medium leading-[26px] text-white">
                    <svg
                      className="animate-spin h-5 w-5 mr-2 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    Processing your payment...
                  </span>
                ) : (
                  <span className="text-[15px] font-medium leading-[26px] text-white">Pay Now</span>
                )}
              </div>
            </button>
          </div>
        )}

    </div >
  );
};

interface SummarySectionProps {
  title?: string;
  details: Array<{ label: string; value: string; isBold?: boolean }>;
  noBorder?: boolean;
  bold?: boolean;
}

const SummarySection: React.FC<SummarySectionProps> = ({ title, details, noBorder }) => (
  <div className={`flex flex-col gap-[8px] p-4 rounded-md ${noBorder ? '' : 'border border-gray-200'}`}>
    {title && <p className="text-[14px] font-bold leading-[21px]">{title}</p>}
    {details.map((item, index) => (
      <div key={index} className="flex flex-row justify-between text-wrap">
        <div className={`text-[14px] leading-[21px] min-w-[50%] ${item.isBold ? 'font-bold' : 'font-normal'}`}>{item.label}</div>
        <div className={`text-[14px] leading-[21px] min-w-[50%] text-end break-words whitespace-normal ${item.isBold ? 'font-bold' : 'font-normal'}`}>{item.value}</div>
      </div>
    ))}
  </div>
);

export default PaymentSummary;
