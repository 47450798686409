// src/api/index.ts
import { ENDPOINTS } from './endpoints';
import { AmountInformation, PaymentSummary, GeneratePaymentLinkResponse, CancelLinkpayPayment } from './types';

export const fetchAmountInformation = async (bookingId: number): Promise<AmountInformation> => {
  const response = await fetch(`${ENDPOINTS.amountInformation}?bookingId=${bookingId}`);
  if (!response.ok) throw new Error('Failed to fetch amount information');
  return response.json();
};

export const cancelPayment = async (bookingId: number): Promise<CancelLinkpayPayment> => {
  const response = await fetch(`${ENDPOINTS.cancelPayment}/${bookingId}`, {
    method: 'DELETE',
  });
  if (!response.ok) throw new Error('Failed to cancel payment');
  return {
    status: response.status,
    message: await response.text()
  };
};

export const generatePaymentLink = async (bookingId: number): Promise<GeneratePaymentLinkResponse> => {
  const response = await fetch(`${ENDPOINTS.generatePaymentLink}/${bookingId}`);
  if (!response.ok) throw new Error('Failed to generate payment link');
  return {
    status: response.status,
    message: await response.text()
  };
};


export const fetchPaymentSummary = async (bookingId: number): Promise<PaymentSummary> => {
  const response = await fetch(`${ENDPOINTS.fetchPaymentSummary}/${bookingId}`);
  if (!response.ok) throw new Error('Failed to fetch payment summary');
  return response.json();
};


export const getPaymentUrl = async (payload:any) => {
  try {
    const response = await fetch(`${ENDPOINTS.getPaymentUrl}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(payload),
    });
    if (!response.ok) throw new Error('Failed to get payment URL');

    const data = await response.json();
    return data;
  } catch (error: any) {
    console.error('Error getting payment URL:', error);
    return { error: error.message || 'Failed to get payment URL' };
  }
};


export const getTransactionStatus = async ( id: any ) => {
  try {
    const response = await fetch(`${ENDPOINTS.getTransactionStatus}/?booking_id=${id}`);
    if (!response.ok) {
      throw new Error(`Error fetching payment status: ${response.statusText}`);
    }
    const data = await response.json();
    return data.data;
  } catch (error) {
    console.error("Failed to fetch transaction status:", error);
  }
  
};

export const updateStatus = async (bookingId: number, status: string, reason: string, paymentType: string, transactionRef: string): Promise<{ success: boolean; timestamp: string }> => {
  try {
    const url = `${ENDPOINTS.updateStatus}?booking_id=${bookingId}&status=${status}&reason=${reason}&payment_method=${paymentType}&pg_transaction_ref=${transactionRef}`;
    const response = await fetch(url, {
      method: 'PUT',
    });

    if (!response.ok) {
      console.error(`Failed to update status. HTTP Status: ${response.status}`);
      throw new Error(`Failed to update status: ${response.statusText}`);
    }

    const responseData = await response.json();

    if (responseData && responseData.data && typeof responseData.data.timestamp === 'string') {
      return { success: true, timestamp: responseData.data.timestamp };
    } else {
      console.warn('Timestamp missing in response data');
      return { success: false, timestamp: '' };
    }
  } catch (error) {
    console.error('Error updating payment status:', error);
    return { success: false, timestamp: '' };
  }
};



export const downloadReceipt= async (bookingId: number): Promise<string> => {
  const response = await fetch(`${ENDPOINTS.fetchPaymentSummary}/${bookingId}`);
  if (!response.ok) throw new Error('Failed to download recepit');
  return response.json();
}
