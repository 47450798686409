import React from 'react';
import failed from '../assets/failed.png'
import Footer from '../components/common/Footer';
import Button from '../components/common/Button';
import { bottomSheet } from "react-simple-bottom-sheet";
import PaymentCancellationReason from './PaymentCancellationReason';

interface PaymentDetail {
  label: string;
  value: string;
}



interface PaymentFailedCVVProps {
  booking_id: number;
  reason: string;
  timestamp: string;
  transcationId: string;
}

const PaymentFailedCVV: React.FC<PaymentFailedCVVProps> = ({ booking_id, reason, timestamp, transcationId }) => {

  const paymentDetails: PaymentDetail[] = [
    { label: 'Transaction ID', value: transcationId },
    { label: 'Payment Type', value: 'Card' },
  ];
  

  const getTokenFromURL = (): string | null => {
    const params = new URLSearchParams(window.location.search);
    return params.get('token');
  };
  
  const handleRetryPayment = () => {
    const baseURL = process.env.REACT_APP_PAYMENT_SUMMARY_URL;
    const token = getTokenFromURL();
    const redirectURL = token ? `${baseURL}/payment-summary?token=${token}` : `${baseURL}/payment-summary`;
    window.location.href = redirectURL;
  };
  
  

  const openCancelConfirmation = () =>
    bottomSheet.create({
      content: <PaymentCancellationReason bookingId={booking_id} />,
    });


  return (
    <div className='flex flex-col'>
      <div className='flex flex-col justify-center items-center gap-3 p-6'>
        <div>
          <img src={failed} alt='success-img' className='w-auto  h-auto object-contain' />
        </div>
        <div className='flex flex-col items-center gap-1 pb-5 text-center'>
          <div className='text-[14px] font-normal leading-[21px]'>Payment Failed</div>
          <div className='text-[24px] font-bold leading-[28px]'>Payment Time Out</div>
          <div className='text-[14px] font-normal leading-[21px]'>{timestamp}</div>
        </div>

        <div className='font-normal text-center text-[14px] leading-[21px] font-gantari text-[#D32F2F]'>
            {reason}
        </div>
      </div>

      <div className='flex flex-col gap-[36px] bg-[#F5F5F5] px-6 pt-6'>
        <div className='flex flex-col'>
          {paymentDetails.map((detail, index) => (
            <div className='payment-details-container-list' key={index}>
              <div className='font-normal text-[14px] leading-[21px]'>{detail.label}</div>
              <div className='font-normal text-[14px] leading-[21px]'>{detail.value}</div>
            </div>
          ))}
        </div>
        <div className='flex flex-col gap-[16px]'>
          <Button
            text='Retry Payment'
            onClick={handleRetryPayment}
          />
          <button
            className='text-center px-2 py-[6px]'
            onClick={openCancelConfirmation}>
            Cancel Payment
          </button>
        </div>


      </div>
      <div className='bg-[#F5F5F5]'>
        <div className='p-6'>
          <Footer />
        </div>
      </div>

    </div>
  );
};

export default PaymentFailedCVV;
