// src/api/endpoints.tsx

export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const ENDPOINTS = {
  amountInformation: `${BASE_URL}payment/amount-information`,
  cancelPayment: `${BASE_URL}payment/cancel-lynkpay-payment`,
  generatePaymentLink: `${BASE_URL}payment/generate-payment-link`,
  fetchPaymentSummary: `${BASE_URL}payment/fetch-payment-summary`,
  updateStatus: `${BASE_URL}payment/update-status`,
  downloadRecepit:`${BASE_URL}payment/download-receipt`,
  getPaymentUrl: `${BASE_URL}payment/get-payment-url`,
  getTransactionStatus: `${BASE_URL}payment/get-payment-status`,
};
