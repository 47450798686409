import { jwtVerify, JWTVerifyResult } from 'jose';
import { useNavigate } from 'react-router-dom';

interface DecodedToken {
  booking_id: string;
  expires_in: number;
  iat: number;
}
const SECRET_KEY = process.env.REACT_APP_JWT_SECRET_KEY || 'your_secret_key_here';

export const getTokenFromUrl = (): string | null => {
  const queryParams = new URLSearchParams(window.location.search);
  const token = queryParams.get('token');
  if (token) {
      sessionStorage.setItem('token', token);
  }
  return token;
};


export const decodeToken = async (
  token: string,
  navigate: ReturnType<typeof useNavigate>
): Promise<DecodedToken | null> => {
  try {
    const secretKey = new TextEncoder().encode(SECRET_KEY);
    let verifiedToken: JWTVerifyResult;
    try {
      verifiedToken = await jwtVerify(token, secretKey);
    } catch (verificationError) {
      console.error('Failed to verify the token:', verificationError);
      navigate('/payment-link-expired');
      return null;
    }

    // Extract and decode the payload
    const { payload } = verifiedToken;
    console.log('Decoded token payload:', payload);

    // Ensure that the required fields are present in the decoded payload
    if (payload && 'booking_id' in payload) {
      const bookingId = payload.booking_id as string;

      sessionStorage.setItem("bookingId", bookingId);
      return {
        booking_id: payload.booking_id as string,
        expires_in: payload.expires_in as number,
        iat: payload.iat as number,
      };
    } else {
      console.error('Decoded token is missing required fields:', payload);
      return null;
    }
  } catch (decodeError) {
    console.error('Failed to decode the token:', decodeError);
    return null;
  }
};
