// Header.tsx
import logo from '../../assets/logo.webp';

interface HeaderProps {
    showExpiryText?: boolean; 
    timeLeft: string;
}

const Header: React.FC<HeaderProps> = ({ showExpiryText = true, timeLeft }) => {
    return (
        <div className="flex flex-row items-center p-5 flex-wrap justify-between bg-[#F5F5F5] min-h-[55px]">
            <img src={logo} alt="Logo" className="w-[120px] h-[26px]" />
            {showExpiryText && (
                <p className="text-sm font-lato">
                    This screen expires in: <b>{timeLeft}</b>
                </p>
            )}
        </div>
    );
};

export default Header;
