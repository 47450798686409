import React, { useState } from "react";
import failed from "../assets/failed.png";
import Footer from "../components/common/Footer";
import DropDown from "../components/common/DropDown";
import { SelectChangeEvent } from "@mui/material";
import CustomTextField from "../components/common/CustomTextField";
import Button from "../components/common/Button";
import { bottomSheet } from "react-simple-bottom-sheet";
import PaymentFailedCancelled from "./PaymentFailedCancelled";
import { updateStatus } from '../api';

interface PaymentCancellationReason {
  bookingId: any;
}

const PaymentCancellationReason: React.FC<PaymentCancellationReason> = ({ bookingId }) => {
  console.log("Booking ID:", bookingId);


  const [reason, setReason] = useState<string>("");
  const [textFieldValue, setTextFieldValue] = useState<string>("");
  const [paymentStatus, setPaymentStatus] = useState<'PAID' | 'FAILED' | 'CANCELLED' | null>(null);

  const handleSelectValueChange = (event: SelectChangeEvent) => {
    setReason(event.target.value as string);
  };

  const handleTextFieldValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextFieldValue(event.target.value);
  };

  const handleBackToPayment = () => {
    bottomSheet.close();
  };

  const handleCancelPaymentClick = async () => {
    if (!reason) {
      console.error('Cancellation reason is required');
      return;
    }
    const combinedReason = `${reason}${textFieldValue ? ` - ${textFieldValue}` : ''}`;

    const { success, timestamp } = await cancelPayment(combinedReason);
    if (success) {
      window.location.reload();
      setTimeout(() => openCancelConfirmation(timestamp), 100);
    }
  };

  const cancelPayment = async (combinedReason: string) => {
    try {
      if (!bookingId || isNaN(Number(bookingId))) {
        return { success: false, timestamp: '' }; 
      }

      const { success, timestamp }  = await updateStatus(Number(bookingId), 'CANCELLED', combinedReason, 'NONE', '');
      if (success) {
        sessionStorage.setItem(`paymentStatus-${bookingId}`, 'CANCELLED')
        console.log('Payment status updated to CANCELLED');
        return { success: true, timestamp }; 
      }
      return { success: false, timestamp: '' };
    } catch (error) {
      console.error('Error updating failed status:', error);
      return { success: false, timestamp: '' }; 
    }
  };


  const openCancelConfirmation = (timestamp: string) => {
    bottomSheet.create({
      content: <PaymentFailedCancelled timestamp={timestamp}  />,
    });
  };


  const options = [
    { value: "Delayed", label: "Delayed" },
    { value: "Insufficient Fund", label: "Insufficient Fund" },
    { value: "Change of Plans", label: "Change of Plans" },
    { value: "Found Cheaper Option", label: "Found a Cheaper Option" },
    { value: "No Longer Needed", label: "No Longer Needed" },
  ];

  return (
    <div className="flex flex-col justify-between">
      <div className="flex flex-col items-center justify-center gap-3 px-6 pt-6">
        <div className="flex flex-col justify-center items-center gap-y-[12px]">
          <img
            src={failed}
            alt="failed-img"
            className="w-auto h-auto object-contain"
          />
          <p className="leading-7 font-bold text-[24px] text-center text-[#000000]">
            Are you sure you want to cancel?
          </p>
        </div>
        <div className="flex flex-col gap-2 w-[100%]">
          <div className="flex flex-col gap-1">
            <p className="font-normal text-sm text-[#00000099] px-2">
              Reason for cancellation
            </p>
            <DropDown
              required
              options={options}
              value={reason}
              onChange={handleSelectValueChange}
            />
          </div>
          <div className="flex flex-col gap-1">
            <p className="font-normal text-sm text-[#00000099] px-2">
              Remarks (optional)
            </p>
            <CustomTextField
              value={textFieldValue}
              onChange={handleTextFieldValueChange}
              required={false}
            />
          </div>
          <div className="flex flex-col items-start justify-center gap-[16px] mt-[16px]">
            <Button
              text="Cancel Payment"
              color="#D32F2F"
              onClick={handleCancelPaymentClick}
            />
            <Button
              text="Back to Payment"
              color="none"
              textColor="#000000DE"
              onClick={handleBackToPayment}
            />
          </div>
        </div>
      </div>
      <div className="p-6">
        <Footer />
      </div>
    </div>
  );
};

export default PaymentCancellationReason;
